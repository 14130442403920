
import { PDefaultContainer } from "../../../components";

import { FirstSection, SecondSection, ThirdSection } from '..'

export default function HomePage() {
  return (
    <PDefaultContainer>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
    </PDefaultContainer>
  );
}
